<template>
  <app-form-view
    app="questionnaire"
    model="questionnaireheader"
    api-url="questionnaire/questionnaire-header/"
    :title="$t('menu.questionnaire')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>

        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Master Questionnaire Name"
            type="select-server"
            :label="$t('fields.masterQuestionnaire')"
            :view="view"
            :binds="{
              apiUrl: 'questionnaire/master-questionnaire-header/'
            }"
            v-model="formData.master_questionnaire_header_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterQuestionnaireHeader"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_masterQuestionnaireHeader"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Questionnaire Type"
            type="select-server"
            :label="$t('fields.questionnaireType')"
            :view="view"
            :binds="{
              apiUrl: 'questionnaire/questionnaire-type/'
            }"
            v-model="formData.questionnaire_type_id"
            ref="questionnaireTypeInput"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveQuestionnaireType"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_questionnaireType"
          />
        </v-col>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>Description</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="table"
                  app="questionnaire"
                  model="questionnairedetail"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="headers"
                  :serverItems="questionnaireDetailItems"
                  :clientItems="questionnaireDetailItems"
                  :loading="loading"
                  :server-items-length="questionnaireDetailItemsLength"
                  v-model="questionnaireDetailSelected"
                  @server="getQuestionnaireDetailItems"
                  @client="getQuestionnaireClientDetailItems"
                  @delete="onDeleteItem"
                  @edit="onEdit"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title> Description </v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-dialog
                        v-model="dialogQuestionnaireDetail"
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <slot name="actions">
                            <v-btn
                              v-show="mode === 'edit'"
                              dark
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="onEditQuestionnaire"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              v-show="
                                questionnaireDetailSelected.length &&
                                mode === 'edit'
                              "
                              color="error"
                              @click="onDelete(questionnaireDetailSelected)"
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                          </slot>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.sequence"
                                    name="sequence"
                                    :label="$t('fields.sequence')"
                                    type="number"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.code"
                                    name="code"
                                    type="number"
                                    :label="$t('fields.question_code')"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.question"
                                    name="question"
                                    :label="$t('fields.question')"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    name="Question Type"
                                    type="select"
                                    class="w-100"
                                    :label="$t('fields.questionType')"
                                    :items="[
                                      {
                                        label: 'Text',
                                        value: 'Text'
                                      },
                                      {
                                        label: 'MultipleChoice',
                                        value: 'MultipleChoice'
                                      },
                                      { label: 'Checkbox', value: 'Checkbox' }
                                    ]"
                                    v-model="editedItem.question_type"
                                    :view="view"
                                  >
                                  </app-input>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="2" style="text-align: right">
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-show="mode === 'edit'"
                                    @click="onEditQuestionnaireLine()"
                                    v-if="
                                      editedItem.question_type ===
                                        'MultipleChoice' ||
                                      editedItem.question_type === 'Checkbox'
                                    "
                                  >
                                    {{ $t('btn.newItem') }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col col="12">
                                  <app-table
                                    model="questionnaireline"
                                    app="questionnaire"
                                    :headers="questionnaireLineHeaders"
                                    :hide-edit="mode !== 'edit'"
                                    :hide-delete="mode !== 'edit'"
                                    :is-selecteable="false"
                                    :client-items="editingQuestionnaire.lines"
                                    @edit="onEditQuestionnaireLine"
                                    @delete="onDeleteQuestionnaireLine"
                                    v-if="
                                      editedItem.question_type ===
                                        'MultipleChoice' ||
                                      editedItem.question_type === 'Checkbox'
                                    "
                                  >
                                  </app-table>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.total_points"
                                    name="total_points"
                                    :label="$t('fields.totalPoints')"
                                    type="number"
                                    :view="view"
                                    :disabled="
                                      editedItem.question_type ===
                                        'MultipleChoice' ||
                                      editedItem.question_type === 'Checkbox'
                                    "
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.note"
                                    name="note"
                                    :label="$t('fields.note')"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeQuestionnaireDetail"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveQuestionnaireDetail"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog
                        v-model="dialogQuestionnaireLine"
                        max-width="500px"
                      >
                        <v-card>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                  cols="12"
                                  class="app-input--approve-wrapper"
                                >
                                  <app-input
                                    v-model="editingQuestionnaireLine.choice"
                                    name="choice"
                                    :label="$t('fields.choice')"
                                    :view="view"
                                  />
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col
                                  cols="12"
                                  class="app-input--approve-wrapper"
                                >
                                  <app-input
                                    v-model="editingQuestionnaireLine.points"
                                    name="points"
                                    type="number"
                                    :label="$t('fields.points')"
                                    :view="view"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="onCloseQuestionnaireLine"
                            >
                              {{ $t('btn.cancel') }}
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="onSaveQuestionnaireLine"
                            >
                              {{ $t('btn.save') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput'
import AppTable from '@components/AppTable.vue'

export default {
  name: 'questionnaire-type-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      tab: null,
      loading: false,
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_name',
        'approve_masterQuestionnaireHeader',
        'approve_questionnaireType'
      ],
      questionnaireDetailItems: [],
      questionnaireDetailSelected: [],
      questionnaireClientItems: [],
      questionnaireDetailItemsLength: 0,
      dialogQuestionnaireDetail: false,
      editedItem: {
        sequence: null,
        question: null,
        question_type: null,
        choice: null,
        note: null,
        code: null
      },
      editingQuestionnaireLine: {},
      editingQuestionnaire: {},
      dialogQuestionnaireLine: false,
      apiMethod: 'post'
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'questionnaireCreate'
    })
  },
  watch: {
    'formData.master_questionnaire_header_id': 'fetchQuestionnaireType',
    'formData.name': function (value) {
      if (this.$route.name === 'questionnaireEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'questionnaireEdit',
          text: value ?? 'N/A',
          to: {
            name: 'questionnaireEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    headers() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.question_code'),
          value: 'code',
          hideFilter: true
        },
        {
          text: this.$t('fields.question'),
          value: 'question',
          hideFilter: true
        },
        {
          text: this.$t('fields.questionType'),
          value: 'question_type',
          hideFilter: true
        },

        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    questionnaireLineHeaders() {
      return [
        {
          text: this.$t('fields.choice'),
          value: 'choice',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.points'),
          value: 'points',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  methods: {
    async fetchQuestionnaireType(newVal, oldVal) {
      if (this.formData.master_questionnaire_header_id) {
        if (newVal.questionnaire_type_id != oldVal.questionnaire_type_id) {
          this.$refs.questionnaireTypeInput.created()
          this.formData.questionnaire_type_id =
            this.formData.master_questionnaire_header_id.questionnaire_type_id
        }
      } else {
        this.formData.questionnaire_type_id = null
      }
    },
    getQuestionnaireDetailItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        const ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          questionnaire_header_id: this.$route.params.id
        }
      } else {
        this.params = { questionnaire_header_id: this.$route.params.id }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'questionnaire/questionnaire-detail/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.questionnaireDetailItemsLength = data.count
          this.questionnaireDetailItems = data.results
          this.questionnaireClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getQuestionnaireClientDetailItems(multiSearch) {
      this.questionnaireClientItems = this.questionnaireDetailItems.filter(
        (item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        }
      )
    },
    closeQuestionnaireDetail() {
      this.editedItem = {}
      this.dialogQuestionnaireDetail = false
    },
    async saveQuestionnaireDetail() {
      this.loading = true

      const data = {
        questionnaire_header_id: this.$route.params.id,
        sequence: this.editedItem.sequence,
        question: this.editedItem.question,
        question_type: this.editedItem.question_type,
        note: this.editedItem.note,
        code: this.editedItem.code,
        total_points: this.editedItem.total_points,
        active: 'True',
        lines: this.editingQuestionnaire.lines.map((line) => ({
          choice: line.choice,
          points: line.points
        }))
      }

      await this.$api({
        method: this.apiMethod,
        url: `questionnaire/questionnaire-detail/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeQuestionnaireDetail()
      this.getQuestionnaireDetailItems()
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.editingQuestionnaire = Object.assign(
        {
          lines: []
        },
        item
      )
      this.dialogQuestionnaireDetail = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `questionnaire/questionnaire-detail/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    prepareData(data) {
      data.questionnaire_type_id = data.questionnaire_type_id.id
      data.master_questionnaire_header_id =
        data.master_questionnaire_header_id.id
      return data
    },
    onEditQuestionnaire(item) {
      this.editingQuestionnaire = Object.assign(
        {
          lines: []
        },
        item
      )

      this.dialogMasterQuestionnaireDetail = true
    },
    onEditQuestionnaireLine(line) {
      if (line) {
        this.editingQuestionnaireLine = line
      } else {
        this.editingQuestionnaireLine = Object.assign({
          choice: '',
          points: ''
        })
      }
      this.dialogQuestionnaireLine = true
    },
    onDeleteQuestionnaireLine(items) {
      for (const item of items) {
        const i = this.editingQuestionnaire.lines.findIndex(
          (line) => line === item
        )
        if (i >= 0) {
          this.editingQuestionnaire.lines.splice(i, 1)
        }
      }
    },
    async onSaveQuestionnaireLine() {
      const i = this.editingQuestionnaire.lines.findIndex(
        (line) => line === this.editingQuestionnaireLine
      )
      if (i >= 0) {
        this.editingQuestionnaire.lines[i] = this.editingQuestionnaireLine
      } else {
        this.editingQuestionnaire.lines.push(this.editingQuestionnaireLine)
      }

      this.updateTotalPoints()
      this.onCloseQuestionnaireLine()
      return
    },
    onCloseQuestionnaireLine() {
      this.dialogQuestionnaireLine = false
      this.$nextTick(() => {
        this.editingQuestionnaireLine = {}
      })
    },

    updateTotalPoints() {
      let totalPoints = 0

      if (this.editedItem.question_type === 'MultipleChoice') {
        // Get the maximum point value
        totalPoints = Math.max(
          ...this.editingQuestionnaire.lines.map((line) => line.points)
        )
      } else if (this.editedItem.question_type === 'Checkbox') {
        // Sum the point values
        totalPoints = this.editingQuestionnaire.lines.reduce((sum, line) => {
          return sum + line.points
        }, 0)
      }

      this.editedItem.total_points = totalPoints
    }
  }
}
</script>
